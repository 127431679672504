<style>
    .stock-movement-icon-green {
        color: #4FA601;
        font-size: 28px;
    }

    .stock-movement-icon-grey {
        color: #78828C;
        font-size: 28px;
    }
</style>

<template>
    <div v-if="params.data">
        <div v-if="this.params.data.movement > 0">
            <font-awesome-icon class="stock-movement-icon-green" :icon="['fas', 'chart-line']" />
        </div>
        <div v-if="this.params.data.movement < 0">
            <font-awesome-icon class="stock-movement-icon-grey" :icon="['fas', 'chart-line-down']" />
        </div>
    </div>
</template>

<script>
    import * as Vue from "vue";
    import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

    export default {
        name: 'ListStockMovementsDetailRenderer',
        components: {
            FontAwesomeIcon: Vue.shallowRef(FontAwesomeIcon)
        }
    }        
</script>