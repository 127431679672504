<style scoped>
    :deep(.fieldset-article-kind .fieldset) {
        flex-direction: column;
    }

    :deep(.fieldset-article-kind:first-child) {
        margin-right: 20px;
    }

    :deep(.fieldset-article-kind .fieldset > div) {
        display: flex;
        margin: 6px 0px;
    }

    #topbar-button-container {
        display: flex;
    }

    #topbar-button-container :deep(button:nth-child(1)), #topbar-button-container :deep(button:nth-child(2)) {
        margin-right: 20px;
    }

    .article-detail-container {
        display: flex;
        align-items: center;
        flex: 1;
        justify-content: space-between;
    }

    .article-detail-container div:nth-child(1) {
        flex: 2;
    }

    .article-detail-container div:nth-child(2) {
        flex: 1;
    }

    :deep(.articles-list .inputeo) {
        margin: 0px 10px;
        width: 100px;
    }

    .modal-menu-title {
        text-align: center;
        color: rgb(50, 60, 70);
        font-size: 13px;
        margin: 30px 0px 30px 0px;
    }

    #modal-create-edit-article #button-create-stock-movement-container {
        width: 300px;
        margin: 20px auto;
    }

    #modal-create-edit-article table {
        text-align: left;
        border-collapse: collapse;
    }

    #modal-create-edit-article #table-stock-movement-container {
        max-height: 70vh;
        overflow-y: auto;
        display: block;
    }

    #modal-create-edit-article table thead {
        background-color: rgb(240, 243, 246);
        color: rgb(100, 110, 120);
        font-size: 11px;
        text-transform: uppercase;
    }

    #modal-create-edit-article table thead th {
        font-weight: 500;
        padding: 13px 20px;
        white-space: nowrap;
    }

    #modal-create-edit-article table tbody tr {
        border-bottom: 1px solid rgb(230, 235, 240);
    }

    #modal-create-edit-article table tbody td {
        font-weight: 500;
        font-size: 13px;
        color: rgb(30, 40, 50);
    }

    #modal-create-edit-article table tbody td {
        padding: 15px 20px;
    }

    #modal-create-edit-article table#table-quantity thead th, #modal-create-edit-article table#table-quantity tbody td {
        text-align: center;
    }

    #modal-create-edit-article .stock-movement-direction-up, #modal-create-edit-article .stock-movement-direction-down {
        font-size: 24px;
        padding: 5px 20px;
    }

    #modal-create-edit-article .stock-movement-direction-up {
        color: #4FA601;
    }

    #modal-create-edit-article .stock-movement-direction-down {
        color: #78828C;
    }

    #modal-create-edit-article .stock-movement-button-action-container, #modal-create-edit-article .analytic-button-action-container {
        padding: 5px 20px;
    }

    #modal-create-edit-article .stock-movement-button-action-container :deep(button), #modal-create-edit-article .analytic-button-action-container :deep(button) {
        background: linear-gradient(90deg,#ff6b00,#ff9100);
        font-size: 16px;
        color: #fff;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        width: 30px;
        height: 30px;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #modal-create-edit-article #table-analytic-affairs-container {
        max-height: 90vh;
        overflow-y: auto;
        display: flex;
        align-items: flex-start;
    }

    #modal-create-edit-article #table-analytic-affairs-container table:not(:last-child) {
        margin-right: 40px;
    }

    #modal-generate-inventory :deep(.modal-container) {
        min-height: unset;
    }

    #modal-create-edit-article table#table-purchase-history {
        width: 100%;
    }
</style>

<template>
    <CustomTopbar>
        <template v-slot:custom-topbar>
            <div id="topbar-button-container">
                <BaseButton buttonText="Créer un article" @click="displayCreateArticleModal">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'plus']" />
                    </template>
                </BaseButton>
                <BaseButton buttonText="Créer un mouvement" @click="displayCreateStockMovementModal()">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'chart-line']" />
                    </template>
                </BaseButton>
                <BaseButton buttonText="Générer l'inventaire" @click="displayGenerateInventoryModal">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'file-export']" />
                    </template>
                </BaseButton>
            </div>
        </template>
    </CustomTopbar>
    <Aggrid
        apiRoute='article/list'
        :apiParams="filter"
        :columnDefs="columnDefs"
        :cellRendererFunctions="cellRendererFunctions"
        :valueFormatterFunctions="valueFormatterFunctions"
        :frameworkComponents="frameworkComponents"
        :context="context"
        aggridHeightSubstract="100px"
        @rowClicked="((event) => displayEditArticleModal(event))"
    >
        <!--
        apiRoute='stockMovement/list'
        :apiParams="{
            articleId: article.id
        }"
        :columnDefs="columnDefs2"
        :frameworkComponents="frameworkComponents2"
        :context="context"
        -->
        <template v-slot:filter>
            <div>
                <BaseCheckbox label="Afficher les articles archivés" @onChange="displayArchivedArticles" v-if="$store.getters.isAdmin || $store.getters.isWorkshopManager"/>
            </div>
            <div>
                <BaseInput v-model="search" label="Rechercher un article" type="text" name="search" @onChange="onSearchInputChange">
                    <template v-slot:iconBefore>
                        <font-awesome-icon :icon="['fas', 'search']" />
                    </template>
                </BaseInput>
            </div>
        </template>
    </Aggrid>
    <Modal id="modal-create-edit-article" v-show="displayModalCreateOrEditArticle === true" :fullscreen="true" mode="menu">
        <template v-slot:modalMenu>
            <ModalMenu ref="articleModalMenu" :menuItems="menuItems" :startTab="startArticleModalTab" @onExit="onModalExit" v-on:update:startTab="startArticleModalTab = $event">
                <template v-slot:modalMenuHead>
                    <div class="modal-menu-title">
                        <span v-show="createOrEditMode === 'create'">Créer un article</span>
                        <span v-show="createOrEditMode === 'edit'">Modifier un article</span>
                    </div>
                </template>

                <template v-slot:modalTabs>
                    <div data-tab-name="kindFamily">
                        <form>
                            <div>
                                <FieldSet
                                    class="fieldset-article-kind"
                                    label="Nature d'article"
                                >
                                    <template v-slot:content>
                                        <div>
                                            <BaseSelect
                                                v-model="article.articleKind"
                                                label="Nature d'article"
                                                name="articleKind"
                                                api="articleKind/search"
                                                fieldValue="id"
                                                fieldLabel="label"
                                                :required="true"
                                                :error="articleFormErrors?.step1?.articleKind?.error?.message"
                                                @onChange="onArticleFormInputChange($event, 1)"
                                                :displayError="displayError"
                                                :searchable="true"
                                                :minChars="2"
                                                :maxHeight="300"
                                            />
                                            <BaseInput
                                                v-model="article.articleKindBuyAccount"
                                                label="Compte d'achat"
                                                type="text"
                                                :disabled="true"
                                            />
                                            <BaseInput
                                                v-model="article.articleKindSaleAccount"
                                                label="Compte de vente"
                                                type="text"
                                                :disabled="true"
                                            />
                                        </div>
                                        <div>
                                            <RadioButtonContainer
                                                label="Afficher sur le devis"
                                            >
                                                <BaseRadioButton
                                                    v-model="article.articleKindIsOnQuotation"
                                                    label="Oui"
                                                    name="articleKindIsOnQuotation"
                                                    value="true"
                                                    :disabled="true"
                                                />
                                                <BaseRadioButton
                                                    v-model="article.articleKindIsOnQuotation"
                                                    label="Non"
                                                    name="articleKindIsOnQuotation"
                                                    value="false"
                                                    :disabled="true"
                                                />
                                            </RadioButtonContainer>
                                            <RadioButtonContainer
                                                label="Sortir de stock"
                                            >
                                                <BaseRadioButton
                                                    v-model="article.articleKindHandleOutOfStock"
                                                    label="Oui"
                                                    name="articleKindHandleOutOfStock"
                                                    value="true"
                                                    :disabled="true"
                                                />
                                                <BaseRadioButton
                                                    v-model="article.articleKindHandleOutOfStock"
                                                    label="Non"
                                                    name="articleKindHandleOutOfStock"
                                                    value="false"
                                                    :disabled="true"
                                                />
                                            </RadioButtonContainer>
                                        </div>
                                    </template>
                                </FieldSet>
                                <FieldSet
                                    class="fieldset-article-kind"
                                    label="Article"
                                >
                                    <template v-slot:content>
                                        <div>
                                            <BaseInput
                                                v-model="article.buyAccount"
                                                label="Compte d'achat"
                                                type="text"
                                                tabIndex="1"
                                                name="buyAccount"
                                            />
                                            <BaseInput
                                                v-model="article.saleAccount"
                                                label="Compte de vente"
                                                type="text"
                                                tabIndex="1"
                                                name="saleAccount"
                                            />
                                        </div>
                                        <div>
                                            <RadioButtonContainer
                                                label="Afficher sur le devis"
                                            >
                                                <BaseRadioButton
                                                    v-model="article.isOnQuotation"
                                                    label="Oui"
                                                    name="isOnQuotation"
                                                    value="true"
                                                />
                                                <BaseRadioButton
                                                    v-model="article.isOnQuotation"
                                                    label="Non"
                                                    name="isOnQuotation"
                                                    value="false"
                                                />
                                                <BaseRadioButton
                                                    v-model="article.isOnQuotation"
                                                    label="Selon nature"
                                                    name="isOnQuotation"
                                                    value="null"
                                                />
                                            </RadioButtonContainer>
                                            <RadioButtonContainer
                                                label="Sortir de stock"
                                            >
                                                <BaseRadioButton
                                                    v-model="article.handleOutOfStock"
                                                    label="Oui"
                                                    name="handleOutOfStock"
                                                    value="true"
                                                />
                                                <BaseRadioButton
                                                    v-model="article.handleOutOfStock"
                                                    label="Non"
                                                    name="handleOutOfStock"
                                                    value="false"
                                                />
                                                <BaseRadioButton
                                                    v-model="article.handleOutOfStock"
                                                    label="Selon nature"
                                                    name="handleOutOfStock"
                                                    value="null"
                                                />
                                            </RadioButtonContainer>
                                        </div>
                                    </template>
                                </FieldSet>
                            </div>
                            <div>
                                <BaseSelect
                                    v-model="article.articleFamilyId"
                                    label="Famille d'article"
                                    name="articleFamilyId"
                                    fieldValue="id"
                                    fieldLabel="label"
                                    api="articleFamily/search"
                                    :required="true"
                                    :error="articleFormErrors?.step1?.articleFamilyId?.error?.message"
                                    @onChange="onArticleFormInputChange($event, 1)"
                                    :displayError="displayError"
                                    :searchable="true"
                                    :minChars="2"
                                    :maxHeight="300"
                                />
                            </div>
                        </form>
                    </div>

                    <div data-tab-name="general">
                        <form>
                            <div>
                                <BaseInput
                                    v-model="article.code"
                                    label="Code article"
                                    type="text"
                                    tabIndex="1"
                                    name="code"
                                    validator="text"
                                    :error="articleFormErrors?.step2?.code?.error?.message"
                                    @onChange="onArticleFormInputChange($event, 2)"
                                    :displayError="displayError"
                                    :disabled="createOrEditMode !== 'create'"
                                    maxLength="18"
                                />
                                <BaseInput
                                    v-model="article.quotationLabel"
                                    label="Référence devis"
                                    type="text"
                                    tabIndex="2"
                                    name="quotationLabel"
                                    validator="text"
                                    :error="articleFormErrors?.step2?.quotationLabel?.error?.message"
                                    @onChange="onArticleFormInputChange($event, 2)"
                                    :displayError="displayError"
                                />
                                <BaseInput
                                    v-model="article.unit"
                                    label="Unité"
                                    type="text"
                                    tabIndex="3"
                                    name="unit"
                                />
                            </div>
                            <div>
                                <BaseInput
                                    v-model="article.label"
                                    label="Libellé"
                                    type="text"
                                    tabIndex="4"
                                    name="label"
                                    validator="text"
                                    :error="articleFormErrors?.step2?.label?.error?.message"
                                    @onChange="onArticleFormInputChange($event, 2)"
                                    :displayError="displayError"
                                />
                                <BaseSelect
                                    v-model="article.establishment"
                                    label="Établissement"
                                    :defaultOptions="establishments"
                                    name="establishment"
                                    :error="articleFormErrors?.step2?.establishment?.error?.message"
                                    @onChange="onArticleFormInputChange($event, 2)"
                                    :displayError="displayError"
                                    :required="true"
                                />
                            </div>
                            <div>
                                <BaseInput
                                    v-model="article.buyPrice"
                                    label="Tarif achat"
                                    type="text"
                                    tabIndex="5"
                                    name="buyPrice"
                                    validator="price"
                                    :error="articleFormErrors?.step2?.buyPrice?.error?.message"
                                    @onChange="onArticleFormInputChange($event, 2)"
                                    :displayError="displayError"
                                />
                                <BaseInput
                                    v-model="article.salePrice"
                                    label="Tarif vente"
                                    type="text"
                                    tabIndex="6"
                                    name="salePrice"
                                    validator="price"
                                    :error="articleFormErrors?.step2?.salePrice?.error?.message"
                                    @onChange="onArticleFormInputChange($event, 2)"
                                    :displayError="displayError"
                                />
                            </div>
                            <div>
                                <BaseSelect
                                    v-if="article?.articleKind?.value === -1"
                                    v-model="article.articleType"
                                    label="Type"
                                    tabIndex="8"
                                    name="type"
                                    :error="articleFormErrors?.step2?.type?.error?.message"
                                    @onChange="onArticleFormInputChange($event, 2)"
                                    :displayError="displayError"
                                    :defaultOptions="articleType"
                                    :maxHeight="300"
                                />
                            </div>
                        </form>
                    </div>

                    <div data-tab-name="supplierArticle">
                        <form class="form-column">
                            <div>
                                <BaseSelect
                                    v-model="form.supplierCurrent"
                                    label="Ajouter un fournisseur"
                                    name="searchSupplier"
                                    @onChange="onSupplierAdd"
                                    api="supplier/search"
                                    fieldValue="id"
                                    fieldLabel="name"
                                    :searchable="true"
                                    :minChars="1"
                                    :maxHeight="300"
                                />
                                <ListWithDelete v-model="article.suppliers" label="Liste des fournisseurs">
                                    <template v-slot:content="{ item }">
                                        <div>{{ item.label }}</div>
                                    </template>
                                </ListWithDelete>
                            </div>
                            <div>
                                <BaseSelect
                                    v-model="form.articlesEstablishmentFilter"
                                    label="Etablissement"
                                    :defaultOptions="establishments"
                                    name="searchArticleEstablishment"
                                    :display-tag="true"
                                    :disabled="article.articles.length === 0 && createOrEditMode === 'edit'"
                                    fieldLabel="label"
                                    fieldValue="id"
                                />
                            </div>
                            <div>
                                <BaseSelect
                                    v-model="form.articleCurrent"
                                    label="Ajouter un article au kit"
                                    name="searchArticle"
                                    @onChange="onArticleAdd"
                                    api="article/search"
                                    :apiParams="{
                                        onlyArticleWithoutKit: true,
                                        establishment: form.articlesEstablishmentFilter?.value
                                    }"
                                    fieldValue="id"
                                    fieldLabel="label"
                                    :searchable="true"
                                    :disabled="article.articles.length === 0 && createOrEditMode === 'edit'"
                                    :maxHeight="300"
                                />
                                <ListWithDelete class="articles-list" v-model="article.articles" label="Liste des articles du kit">
                                    <template v-slot:content="{ item, index }">
                                        <div class="article-detail-container">
                                            <div>{{ item.label }}</div>
                                            <BaseInput
                                                v-model="article.articles[index].quantity"
                                                type="text"
                                                :label="'Qté' + (item.unit ? ` (${item.unit})` : '')"
                                                :name="'quantity_' + index"
                                                validator="decimal_2"
                                                :error="articleFormErrors?.step3['quantity_' + index]?.error?.message"
                                                @onChange="onArticleFormInputChange($event, 3)"
                                                :displayError="displayError"
                                                :isSmall="true"
                                                :required="true"
                                            />
                                        </div>
                                    </template>
                                </ListWithDelete>
                            </div>
                        </form>
                    </div>

                    <div data-tab-name="stock">
                        <form>
                            <table id="table-quantity">
                                <thead>
                                    <tr>
                                        <th>Quantité en stock</th>
                                        <th>Quantité en commande</th>
                                        <th>Quantité nomenclature</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {{ article.quantity }}
                                        </td>
                                        <td>
                                            {{ article.quantityArticlesOrdered }}
                                        </td>
                                        <td>
                                            {{ article.quantityArticlesNomenclature }}
                                        </td>
                                        <td>
                                            {{ article.quantity + article.quantityArticlesOrdered - article.quantityArticlesNomenclature }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div id="button-create-stock-movement-container">
                                <BaseButton class="orange-button" buttonText="Créer un mouvement" @click="createStockMovementFromArticle">
                                    <template v-slot:iconBefore>
                                        <font-awesome-icon :icon="['fas', 'plus']" />
                                    </template>
                                </BaseButton>
                            </div>
                            <div id="table-stock-movement-container" v-if="article.stockMovements.length > 0">
                                <Aggrid
                                    apiRoute='stockMovement/list'
                                    :apiParams="{
                                        articleId: article.id
                                    }"
                                    :columnDefs="columnDefs2"
                                    :cellRendererFunctions="cellRendererFunctions2"
                                    :valueFormatterFunctions="valueFormatterFunctions2"
                                    :frameworkComponents="frameworkComponents2"
                                    :context="context"
                                />
                            </div>
                        </form>
                    </div>

                    <div data-tab-name="analytic">
                        <form>
                            <div id="table-analytic-affairs-container">
                                <table v-if="article?.analytic?.affairs?.length > 0">
                                    <thead>
                                        <tr>
                                            <th>Affaire</th>
                                            <th style="text-align: center;">Quantité utilisée</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(affair, index) in article.analytic.affairs" :key="index">
                                            <template v-if="index % 2 === 0">
                                                <td>{{ affair.affairNumber }}</td>
                                                <td style="text-align: center;">{{ affair.movement }}</td>
                                                <td class="analytic-button-action-container">
                                                    <BaseButton class="orange-button" buttonText="" @click="openAffair(affair.affairId)">
                                                        <template v-slot:iconBefore>
                                                            <font-awesome-icon :icon="['fas', 'search']" />
                                                        </template>
                                                    </BaseButton>
                                                </td>
                                            </template>
                                        </tr>
                                        <tr>
                                            <td style="font-weight: bold;">Total</td>
                                            <td style="text-align: center;">{{ article.analytic.totalArticleUsedAffair }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table v-if="article?.analytic?.affairs?.length > 1">
                                    <thead>
                                        <tr>
                                            <th>Affaire</th>
                                            <th style="text-align: center;">Quantité utilisée</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(affair, index) in article.analytic.affairs" :key="index">
                                            <template v-if="index % 2 === 1">
                                                <td>{{ affair.affairNumber }}</td>
                                                <td style="text-align: center;">{{ affair.movement }}</td>
                                                <td class="analytic-button-action-container">
                                                    <BaseButton class="orange-button" buttonText="" @click="openAffair(affair.affairId)">
                                                        <template v-slot:iconBefore>
                                                            <font-awesome-icon :icon="['fas', 'search']" />
                                                        </template>
                                                    </BaseButton>
                                                </td>
                                            </template>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </form>
                    </div>

                    <div data-tab-name="purchaseHistory">
                        <form>
                            <table id="table-purchase-history" v-if="article?.purchaseHistory?.length > 0">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Article</th>
                                        <th>Fournisseur</th>
                                        <th style="text-align: center;">Quantité</th>
                                        <th style="text-align: center;">Prix d'achat</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(purchaseHistory, index) in article.purchaseHistory" :key="index">
                                        <td>{{purchaseHistory.date }}</td>
                                        <td>{{purchaseHistory.article }}</td>
                                        <td>{{purchaseHistory.supplier }}</td>
                                        <td style="text-align: center;">{{ purchaseHistory.quantity }}</td>
                                        <td style="text-align: center;">{{ formatPrice(purchaseHistory.buyPrice) }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </form>
                    </div>
                </template>

                <template v-slot:modalMenuFooter>
                    <BaseButton class="orange-button" buttonText="Enregistrer" @click="onValidateArticleForm">
                        <template v-slot:iconBefore>
                            <font-awesome-icon :icon="['fas', 'save']" />
                        </template>
                    </BaseButton>
                </template>
            </ModalMenu>
        </template>
    </Modal>

    <Modal v-show="displayModalCreateStockMovement">
        <template v-slot:modalIcon>
            <font-awesome-icon :icon="['fas', 'chart-line']" />
        </template>
        <template v-slot:modalTitle>
            <div>Ajouter un mouvement de stock</div>
        </template>
        <template v-slot:modalContent>
            <form>
                <div>
                    <BaseDatepicker
                        v-model="stockMovement.date"
                        label="Date du mouvement"
                        name="date"
                        :required="true"
                        :error="stockMovementFormErrors?.date?.error?.message"
                        @onChange="onStockMovementFormInputChange"
                        :displayError="displayErrorModalCreateStockMovement"
                        :displayTime="true"
                    />
                </div>
                <div>
                    <BaseSelect
                        v-model="stockMovement.affair"
                        label="Affaire"
                        name="affairId"
                        :required="false"
                        @onChange="onStockMovementFormInputChange"
                        fieldValue="id"
                        fieldLabel="name"
                        :searchable="true"
                        api="affair/search"
                        :minChars="2"
                    />
                </div>
                <div>
                    <BaseSelect
                        v-model="stockMovement.articlesEstablishmentFilter"
                        label="Etablissement"
                        :defaultOptions="establishments"
                        name="searchArticleEstablishment"
                        :display-tag="true"
                        fieldLabel="label"
                        fieldValue="id"
                    />
                </div>
                <div>
                    <BaseSelect
                        v-model="stockMovement.article"
                        label="Article"
                        name="articleId"
                        :required="true"
                        :error="stockMovementFormErrors?.articleId?.error?.message"
                        @onChange="onStockMovementFormInputChange"
                        :displayError="displayErrorModalCreateStockMovement"
                        fieldValue="id"
                        fieldLabel="label"
                        :searchable="true"
                        api="article/search"
                        :apiParams="{
                            onlyStock: true,
                            establishment: stockMovement.articlesEstablishmentFilter?.value
                        }"
                    />
                </div>
                <div>
                    <BaseSelect
                        v-model="stockMovement.type"
                        label="Type"
                        :required="true"
                        :defaultOptions="stockMovementType"
                    />
                </div>
                <div>
                    <BaseSelect
                        v-model="stockMovement.kindIn"
                        label="Genre"
                        :required="true"
                        :defaultOptions="stockMovementKindIn"
                        v-if="stockMovement?.type?.value === 'in'"
                    />
                </div>
                <div>
                    <BaseSelect
                        v-model="stockMovement.kindOut"
                        label="Genre"
                        :required="true"
                        :defaultOptions="stockMovementKindOut"
                        v-if="stockMovement?.type?.value === 'out'"
                    />
                </div>
                <div>
                    <BaseInput
                        v-model="stockMovement.quantity"
                        label="Quantité"
                        type="text"
                        name="quantity"
                        validator="decimal_2"
                        :required="true"
                        :error="stockMovementFormErrors?.quantity?.error?.message"
                        @onChange="onStockMovementFormInputChange"
                        :displayError="displayErrorModalCreateStockMovement"
                    />
                </div>
                <div>
                    <BaseInput
                        v-model="stockMovement.buyPrice"
                        label="Prix d'achat"
                        type="text"
                        name="buyPrice"
                        validator="price"
                        :required="true"
                        :error="stockMovementFormErrors?.buyPrice?.error?.message"
                        @onChange="onStockMovementFormInputChange"
                        :displayError="displayErrorModalCreateStockMovement"
                        v-if="stockMovement?.type?.value === 'in'"
                    />
                </div>
                <div>
                    <BaseInput
                        v-model="stockMovement.comment"
                        label="Commentaire"
                        type="textarea"
                        name="comment"
                    />
                </div>
            </form>
        </template>
        <template v-slot:modalButtons>
            <BaseButton class="white-button" @click="closeModalCreateStockMovement" buttonText="Fermer">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'times']" />
                </template>
            </BaseButton>
            <BaseButton class="orange-button" @click="createStockMovement" buttonText="Valider">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'check']" />
                </template>
            </BaseButton>
        </template>
    </Modal>
    <Modal id="modal-generate-inventory" v-show="displayModalGenerateInventory">
        <template v-slot:modalIcon>
            <font-awesome-icon :icon="['fas', 'file-export']" />
        </template>
        <template v-slot:modalTitle>
            <div>GÉNÉRER L'INVENTAIRE</div>
        </template>
        <template v-slot:modalContent>
            <form>
                <div>
                    <BaseCheckbox
                        v-model="inventory.basicInventoryWanted"
                        label="Afficher les totaux sur l'export"
                    />
                </div>
                <div>
                    <BaseDatepicker
                        v-model="inventory.endedDate"
                        label="Date de fin"
                        name="endedDate"
                        :error="inventoryFormErrors.endedDate?.error?.message"
                        @onChange="onFormGenerateInventoryInputChange"
                        :required="true"
                        :displayError="displayErrorModalGenerateInventory"
                    />
                </div>
            </form>
        </template>
        <template v-slot:modalButtons>
            <BaseButton class="white-button" @click="closeModalGenerateInventory" buttonText="Fermer">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'times']" />
                </template>
            </BaseButton>
            <BaseButton class="orange-button" @click="submitModalGenerateInventory" buttonText="Valider">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'check']" />
                </template>
            </BaseButton>
        </template>
    </Modal>
    <Dialog ref="dialog" />
</template>

<script>
    import { format, parse, isAfter } from "date-fns"
    import CustomTopbar from '../../components/Topbar/CustomTopbar'
    import BaseButton from '../../components/Base/BaseButton'
    import BaseInput from '../../components/Base/BaseInput'
    import BaseSelect from '../../components/Base/BaseSelect'
    import BaseCheckbox from '../../components/Base/BaseCheckbox'
    import BaseRadioButton from '../../components/Base/BaseRadioButton'
    import Aggrid from '../../components/Aggrid/Aggrid'
    import Modal from '../../components/App/Modal'
    import ModalMenu from '../../components/App/ModalMenu'
    import Dialog from '../../components/App/Dialog'
    import FieldSet from '../../components/App/FieldSet'
    import RadioButtonContainer from '../../components/App/RadioButtonContainer'
    import ListWithDelete from '../../components/App/ListWithDelete'
    import BaseDatepicker from "../../components/Base/BaseDatepicker"
    import ListTextWithIconRenderer from '../../components/Aggrid/ListTextWithIconRenderer'
    import ListArticlesActionButtonsRenderer from './ListArticlesActionButtonsRenderer'
    import ListStockMovementsActionButtonsRenderer from '../StockMovement/ListStockMovementsActionButtonsRenderer'
    import ListStockMovementsDetailRenderer from '../StockMovement/ListStockMovementsDetailRenderer'
    import axios from 'axios'
    import { formatPrice } from '../../utils/formatter'
    import changeTypeEnum from '../../enums/changeTypeEnum'
    import { createToast } from 'mosha-vue-toastify';
    import config from '../../config'

    export default {
        name: 'Articles',
        components: {
            CustomTopbar,
            BaseButton,
            BaseInput,
            BaseSelect,
            BaseRadioButton,
            RadioButtonContainer,
            ListWithDelete,
            ListTextWithIconRenderer,
            ListArticlesActionButtonsRenderer,
            ListStockMovementsActionButtonsRenderer,
            ListStockMovementsDetailRenderer,
            Aggrid,
            Modal,
            ModalMenu,
            Dialog,
            FieldSet,
            BaseDatepicker,
            BaseCheckbox
        },
        data() {
            let that = this;

            return {
                columnDefs: [
                    { field: 'code', headerName: `Code article`, flex: 1, sortable: true },
                    { field: 'label', headerName : `Libellé`, flex: 1, sortable: true },
                    { field: 'quotationLabel', headerName : `Référence devis`, flex: 1, sortable: true },
                    { headerName : 'Stock', flex: 1, cellRendererFunctionName: 'StockRendererFunction', suppressMenu: true },
                    {
                        field: 'establishment',
                        headerName: `Établissement`,
                        flex: 1,
                        valueFormatterFunctionName: 'EstablishmentValueFormatterFunction',
                        filter: 'agNumberColumnFilter',
                        customFilter: 'establishment'
                    },
                    {
                        field: 'buyPrice',
                        headerName : `Prix d'achat`,
                        valueFormatterFunctionName: 'BuyPriceValueFormatterFunction',
                        filter: 'agNumberColumnFilter',
                        customFilter: 'buyPrice' },
                    {
                        field: 'salePrice',
                        headerName : `Prix de vente`,
                        flex: 1,
                        valueFormatterFunctionName: 'SalePriceValueFormatterFunction',
                        filter: 'agNumberColumnFilter',
                        customFilter: 'salePrice'
                    },
                    { headerName : `Articles du kit`, flex: 1, cellRendererFunctionName: 'ArticlesRendererFunction' , suppressMenu: true },
                    { headerName : 'Affiché sur devis', flex: 1, cellRenderer: 'ListTextWithIconRenderer', cellRendererParams: {
                        field: 'isOnQuotation',
                        mode: 'boolean'
                    }, suppressMenu: true},
                    { headerName : 'Géré en stock', flex: 1, cellRenderer: 'ListTextWithIconRenderer', cellRendererParams: {
                        field: 'handleOutOfStock',
                        mode: 'boolean'
                    }, suppressMenu: true},
                    { headerName : '', width: 200, cellRenderer: 'ListArticlesActionButtonsRenderer', suppressMenu: true}
                ],
                columnDefs2: [
                    { field: 'date', headerName : `Date`, flex: 1, lockPosition: true, filter: 'agDateColumnFilter' },
                    { field: 'affairNumber', headerName : `Affaire`, flex: 1, lockPosition: true, customFilter: 'affair.affairNumber' },
                    {
                        field: 'buyPrice',
                        headerName : `Prix d'achat`,
                        flex: 1, lockPosition: true,
                        valueFormatterFunctionName: 'BuyPriceValueFormatterFunction',
                        filter: 'agNumberColumnFilter',
                        customFilter: 'buyPrice'
                    },
                    { headerName : `Genre`, flex: 1, lockPosition: true, cellRendererFunctionName: 'GenderRendererFunction', suppressMenu: true },
                    { field: 'comment', headerName : `Commentaire`, flex: 2, lockPosition: true, wrapText: true },
                    { field: 'movement', headerName : `Mouvement`, flex: 1, lockPosition: true, filter: 'agNumberColumnFilter' },
                    { flex: 1, lockPosition: true, cellRenderer: 'ListStockMovementsDetailRenderer'},
                    { flex: 1, lockPosition: true, cellRendererFunctionName: 'CancelationDateRendererFunction', suppressMenu: true },
                    { flex: 1, lockPosition: true, cellRenderer: 'ListStockMovementsActionButtonsRenderer', suppressMenu: true}
                ],
                frameworkComponents: {
                    ListTextWithIconRenderer: ListTextWithIconRenderer,
                    ListArticlesActionButtonsRenderer: ListArticlesActionButtonsRenderer
                },
                frameworkComponents2: {
                    ListStockMovementsActionButtonsRenderer: ListStockMovementsActionButtonsRenderer,
                    ListStockMovementsDetailRenderer: ListStockMovementsDetailRenderer
                },
                cellRendererFunctions: new Map([
                    [
                        'ArticlesRendererFunction',
                        function(param) {
                            let articles = '';
                            for (let article in param?.data?.articles) {
                                if (articles.length > 0) articles += '<br/>';
                                articles += param?.data?.articles[article].label;
                            }
                            return articles;
                        }
                    ],[
                        'StockRendererFunction',
                        function(param) {
                            if (param?.data?.handleOutOfStock) {
                                return param?.data?.quantity;
                            } else {
                                return '/';
                            }
                        }
                    ]
                ]),
                cellRendererFunctions2: new Map([
                    [
                        'GenderRendererFunction',
                        function(param) {
                            return param?.data?.movement > 0 ? that.stockMovementKindIn.find(element => element.value === param?.data?.kind)?.label : that.stockMovementKindOut.find(element => element.value === param?.data?.kind)?.label;
                        }
                    ],[
                        'CancelationDateRendererFunction',
                        function(param) {
                            return param?.data?.deleted ? `Annulé le ${param?.data?.deletedAt}` : '';
                        }
                    ]
                ]),
                valueFormatterFunctions: new Map([
                    [
                        'EstablishmentValueFormatterFunction',
                        function(param) {
                            return param?.data?.establishment?.toUpperCase();
                        }
                    ],[
                        'BuyPriceValueFormatterFunction',
                        function(param) {
                            return formatPrice(param?.data?.buyPrice);
                        }
                    ],[
                        'SalePriceValueFormatterFunction',
                        function(param) {
                            return formatPrice(param?.data?.salePrice);
                        }
                    ]
                ]),
                valueFormatterFunctions2: new Map([
                    [
                        'BuyPriceValueFormatterFunction',
                        function(params){
                            return params?.data?.buyPrice != null ? formatPrice(params?.data?.buyPrice) : '';
                        }
                    ]
                ]),

                context: null,
                displayModalCreateOrEditArticle: false,
                displayModalCreateStockMovement: false,
                displayModalGenerateInventory: false,
                createOrEditMode: null,
                search: '',
                searchTimeout: null,
                article: null,
                stockMovement: null,
                form: {
                    supplierCurrent: null,
                    articleCurrent: null,
                    articleKindCodeCurrent: null,
                    articleFamilyCodeCurrent: null,
                    articlesEstablishmentFilter: null
                },
                filter: {
                    search: '',
                    archivedArticles: false
                },
                displayError: false,
                stockMovementFormErrors: {},
                stockMovementType: [
                    { label: 'Entrée', value: 'in' },
                    { label: 'Sortie', value: 'out' }
                ],
                stockMovementKindIn: [
                    { label: 'Affaire', value: 'affair' },
                    { label: 'Inventaire', value: 'inventory' }
                ],
                stockMovementKindOut: [
                    { label: 'Affaire', value: 'affair' },
                    { label: 'Inventaire', value: 'inventory' },
                    { label: 'Casse', value: 'broken' }
                ],
                displayErrorModalCreateStockMovement: false,
                articleFormErrors: {
                    step1: {},
                    step2: {},
                    step3: {},
                    step4: {}
                },
                displayErrorModalGenerateInventory: false,
                inventoryFormErrors: {},
                menuItems: this.getMenuItems(),
                startArticleModalTab: 'kindFamily',
                formatPrice: formatPrice,
                inventory: null,
                articleType: [
                    {value: 'noType', label: 'Sans type'},
                    {value: 'externalMachining', label: 'Usinage externe'},
                    {value: 'rewind', label: 'Rebobinage'}
                ],
                establishments: [
                    { label: 'EBC', value: 'ebc' },
                    { label: 'SMG', value: 'smg' }
                ],
            }
        },
        beforeMount() {
            this.context = {
                componentParent: this
            };

            this.resetArticle();
            this.resetStockMovement();
            this.resetInventory();
        },
        mounted() {
            if (this.$route.query?.action === 'addArticle') {
                this.displayCreateArticleModal();
            }
        },
        methods: {
            closeModalCreateOrEditArticle() {
                this.resetArticle();
                this.startArticleModalTab = 'kindFamily';
                this.displayModalCreateOrEditArticle = false;
                this.displayError = false;
            },
            displayCreateArticleModal() {
                this.createOrEditMode = 'create';
                this.menuItems = this.getMenuItems();
                this.resetArticle();
                this.displayModalCreateOrEditArticle = true;
            },
            displayEditArticleModal(article) {
                this.createOrEditMode = 'edit';
                this.menuItems = this.getMenuItems();

                axios
                .get(`article/${article.id}`, {
                    toastError: true,
                    showPreloader: true
                })
                .then((result) => {
                    this.prepareArticle(result.data);
                    this.displayModalCreateOrEditArticle = true;

                    let affairsFromArticle = result.data.stockMovements.filter(stockMovement => !stockMovement.deleted && stockMovement.affairNumber != null && stockMovement.movement < 0).map(stockMovement => {
                        return {
                            movement: Math.abs(stockMovement.movement),
                            affairId: stockMovement.affairId,
                            affairNumber: stockMovement.affairNumber
                        };
                    });

                    this.article.analytic = {
                        affairs: affairsFromArticle,
                        totalArticleUsedAffair: affairsFromArticle.length > 1 ? affairsFromArticle.reduce((previous, current) => (previous.movement != null ? previous.movement : previous) + current.movement) : affairsFromArticle[0].movement
                    };
                })
                .catch(() => {});
            },
            displayCopyArticleModal(article) {
                this.createOrEditMode = 'create';
                this.menuItems = this.getMenuItems();
                this.article = {
                    label: article.label,
                    quotationLabel: article.quotationLabel,
                    unit: article.unit,
                    buyPrice: article.buyPrice,
                    salePrice: article.salePrice,
                    buyAccount: article.buyAccount,
                    saleAccount: article.saleAccount,
                    isOnQuotation: article.isOnQuotation,
                    handleOutOfStock: article.handleOutOfStock,
                    articles: article.articles,
                    suppliers: article.suppliers.map(supplier => {
                        return {
                            value: supplier.id,
                            label: supplier.label
                        }
                    }),
                    articleKind: {
                        value: article.articleKind.id,
                        tempLabel: article.articleKind.label,
                        label: article.articleKind.label,
                        data: article.articleKind
                    },
                    articleKindBuyAccount: article.articleKind.buyAccount,
                    articleKindSaleAccount: article.articleKind.saleAccount,
                    articleKindIsOnQuotation: article.articleKind.isOnQuotation,
                    articleKindHandleOutOfStock: article.articleKind.handleOutOfStock,
                    articleFamilyId: {
                        value: article.articleFamily.id,
                        tempLabel: article.articleFamily.label,
                        label: article.articleFamily.label,
                        data: article.articleFamily,
                    },
                    stockMovements: [],
                    analytic: null,
                    articleType: {value:'noType', label:'Sans type'},
                    establishment: this.establishments.find(est => est.value === article.establishment)
                };
                this.displayModalCreateOrEditArticle = true;
            },
            prepareArticle(article) {
                this.article = JSON.parse(JSON.stringify(article));
                this.article.articles = this.article.articles.map((article) => {
                    return {
                        value: article.id,
                        label: article.label,
                        quantity: article.quantity,
                        unit: article.unit
                    }
                });
                this.article.suppliers = this.article.suppliers.map((supplier) => {
                    return {
                        value: supplier.id,
                        label: supplier.label
                    }
                });
                this.article.isOnQuotation = this.article.isOnQuotation === true ? 'true' : this.article.isOnQuotation === false ? 'false' : 'null';
                this.article.handleOutOfStock = this.article.handleOutOfStock  === true ? 'true' :  this.article.handleOutOfStock  === false ? 'false' : 'null';
                this.article.articleKindBuyAccount = this.article.articleKind.buyAccount;
                this.article.articleKindSaleAccount = this.article.articleKind.saleAccount;
                this.article.articleKindIsOnQuotation = this.article.articleKind.isOnQuotation === true ? 'true' : 'false';
                this.article.articleKindHandleOutOfStock = this.article.articleKind.handleOutOfStock === true ? 'true' : 'false';
                this.article.articleKind = {
                    value: article.articleKind.id,
                    label: article.articleKind.label
                };
                this.article.articleFamilyId = {
                    value: article.articleFamily.id,
                    label: article.articleFamily.label
                };
                this.article.establishment = this.establishments.find(est => est.value === article.establishment)
            },
            displayCreateStockMovementModal(article) {
                this.resetStockMovement();
                if (article) {
                    this.stockMovement.article = {
                        value: article.value,
                        label: article.label
                    }

                    this.stockMovement.buyPrice = article.buyPrice;
                }
                this.displayModalCreateStockMovement = true;
            },
            resetArticle() {
                this.article = {
                    label: null,
                    quotationLabel: null,
                    code: null,
                    unit: null,
                    buyPrice: null,
                    salePrice: null,
                    buyAccount: null,
                    saleAccount: null,
                    isOnQuotation: null,
                    handleOutOfStock: null,
                    articles: [],
                    suppliers: [],
                    articleKind: null,
                    articleKindBuyAccount: null,
                    articleKindSaleAccount: null,
                    articleKindIsOnQuotation: null,
                    articleKindHandleOutOfStock: null,
                    articleFamilyId: null,
                    stockMovements: [],
                    analytic: null,
                    articleType: {value:'noType', label:'Sans type'},
                    establishment: null
                };

                this.form.articleKindCodeCurrent = null;
                this.form.articleFamilyCodeCurrent = null;
            },
            resetStockMovement() {
                this.stockMovement = {
                    type: {
                        value: 'in',
                        label: 'Entrée'
                    },
                    kindIn: {
                        value: 'affair',
                        label: 'Affaire'
                    },
                    kindOut: {
                        value: 'affair',
                        label: 'Affaire'
                    },
                    affair: null,
                    article: null,
                    quantity: null,
                    buyPrice: null,
                    comment: null,
                    date: format(new Date(), 'dd/MM/yyyy HH:mm'),
                    articlesEstablishmentFilter: null
                }
            },
            formateArticle(article) {
                article = JSON.parse(JSON.stringify(article));
                article.buyPrice = parseFloat(article.buyPrice);
                article.salePrice = parseFloat(article.salePrice);
                if (article.buyAccount?.trim().length === 0) article.buyAccount = null;
                if (article.saleAccount?.trim().length === 0) article.saleAccount = null;
                article.isOnQuotation = article.isOnQuotation === 'true' ? true : article.isOnQuotation === 'false' ? false : null;
                article.handleOutOfStock = article.handleOutOfStock === 'true' ? true : article.handleOutOfStock === 'false' ? false : null;
                article.articles = article.articles.map((article) => {
                    return {
                        id: article.value,
                        quantity: article.quantity
                    }
                });
                article.suppliers = article.suppliers.map(element => element.value);
                article.articleKindId = article.articleKind?.value;
                article.articleFamilyId = article.articleFamilyId?.value;
                article.establishment = article.establishment.value;
                delete article.articleKind;
                delete article.articleKindBuyAccount;
                delete article.articleKindSaleAccount;
                delete article.articleKindHandleOutOfStock;
                delete article.articleKindIsOnQuotation;
                delete article.articleFamily;
                return article;
            },
            createArticle() {
                if (!this.checkForm()) return;

                axios
                .post('article/create', this.formateArticle(this.article), {
                    toastSuccessMessage: `Article créé`,
                    toastError: true,
                    showPreloader: true
                })
                .then(() => {
                    this.closeModalCreateOrEditArticle();
                    this.emitter.emit('ag-grid-reload');
                })
                .catch(() => {});
            },
            editArticle() {
                if (!this.checkForm()) return;

                axios
                .put('article/update', this.formateArticle(this.article), {
                    toastSuccessMessage: `Modification effectuée`,
                    toastError: true,
                    showPreloader: true
                })
                .then(() => {
                    this.closeModalCreateOrEditArticle();
                    this.emitter.emit('ag-grid-reload');
                })
                .catch(() => {});
            },
            deleteOrEnableArticle(article) {
                this.$refs.dialog.show({
                    type: 'confirm',
                    title: 'Confirmation',
                    message: `Êtes-vous sûr de vouloir ${article.deleted ? 'désarchiver' : 'archiver'} cet article ? ${!article.deleted ? `S'il fait partie d'un kit, il sera retiré de celui-ci` : ''}`
                }).then(() => {
                    axios
                    .put('article/changeDeletedArticle/' + article.id, {
                        deleted: !article.deleted
                    }, {
                        toastSuccessMessage: `Modification effectuée`,
                        toastError: true,
                        showPreloader: true
                    })
                    .then(() => {
                        this.emitter.emit('ag-grid-reload')
                    });
                })
                .catch(() => {});
            },
            onArticleFormInputChange(input, stepIndex) {
                let step = `step${stepIndex}`

                if (input.error?.message !== null) {
                    this.articleFormErrors[step][input.name] = input;
                } else {
                    delete this.articleFormErrors[step][input.name];
                }

                if (input.changeType === changeTypeEnum.USER) {
                    switch (input.name) {
                        case 'articleKind':
                            if (input?.value?.data) {
                                this.article.articleKindBuyAccount = input.value.data.buyAccount;
                                this.article.articleKindSaleAccount = input.value.data.saleAccount;
                                this.article.articleKindIsOnQuotation = input.value.data.isOnQuotation;
                                this.article.articleKindHandleOutOfStock = input.value.data.handleOutOfStock;
                                this.article.buyAccount = input.value.data.buyAccount;
                                this.article.saleAccount = input.value.data.saleAccount;
                            } else {
                                this.article.articleKindBuyAccount = null;
                                this.article.articleKindSaleAccount = null;
                                this.article.articleKindIsOnQuotation = null;
                                this.article.articleKindHandleOutOfStock = null;
                            }

                            if (this.createOrEditMode === 'create') {
                                this.form.articleKindCodeCurrent = input?.value?.data?.code;
                                this.handleArticleCode();
                            }
                        break;
                        case 'articleFamilyId':
                            this.article.articleFamilyId = input?.value;

                            if (this.createOrEditMode === 'create') {
                                this.form.articleFamilyCodeCurrent = input?.value?.data?.code;
                                this.handleArticleCode();
                            }
                        break;
                        case 'code':
                            this.$nextTick(() => {
                                let codeStart = '';
                                if (this.form.articleKindCodeCurrent != null && this.form.articleFamilyCodeCurrent != null) {
                                    codeStart = this.form.articleKindCodeCurrent + this.form.articleFamilyCodeCurrent;
                                }

                                if (this.article.code.substr(0, codeStart.length) !== codeStart) {
                                    this.article.code = (codeStart + this.article.code.substr(codeStart.length + 1)).toUpperCase();
                                } else {
                                    this.article.code = (codeStart + this.article.code.substr(codeStart.length)).toUpperCase();
                                }
                            });

                        break;
                    }
                }

                if (this.displayError) {
                    this.menuItems = this.getMenuItems();
                }
            },
            handleArticleCode() {
                if (this.form.articleKindCodeCurrent != null && this.form.articleFamilyCodeCurrent != null) {
                    this.article.code = this.form.articleKindCodeCurrent + this.form.articleFamilyCodeCurrent;
                }
            },
            onStockMovementFormInputChange(input) {
                if (input.error?.message !== null) {
                    this.stockMovementFormErrors[input.name] = input;
                } else {
                    delete this.stockMovementFormErrors[input.name];
                }

                if (input.changeType === changeTypeEnum.USER) {
                    switch (input.name) {
                        case 'date':
                            // Gestion des erreurs personalisées
                            if (!this.stockMovementFormErrors.date || this.stockMovementFormErrors.date.error?.type === 'custom') {
                                // Si la date du mouvement est dans le futur
                                if (isAfter(parse(this.stockMovement.date, 'dd/MM/yyyy HH:mm', new Date()), new Date())) {
                                    this.stockMovementFormErrors.date = {
                                        name: 'date',
                                        value: this.stockMovement.date,
                                        error: {
                                            type: 'custom',
                                            message: `La date et l'heure du mouvement ne peut pas être dans le futur`
                                        }
                                    }
                                } else {
                                    delete this.stockMovementFormErrors.date;
                                }
                            }
                        break;
                        case 'articleId':
                            this.stockMovement.buyPrice = input?.value?.data?.buyPrice;
                        break;
                    }
                }
            },
            onFormGenerateInventoryInputChange(input) {
                if (input.error?.message !== null) {
                    this.inventoryFormErrors[input.name] = input;
                } else {
                    delete this.inventoryFormErrors[input.name];
                }
            },
            checkForm() {
                this.displayError = true;
                this.menuItems = this.getMenuItems();

                let formIsOk = true;
                for (let key of Object.keys(this.articleFormErrors)) {
                    if (this.articleFormErrors[key] != null && Object.keys(this.articleFormErrors[key]).length > 0) {
                        formIsOk = false;
                        break;
                    }
                }

                return formIsOk;
            },
            checkStockMovementForm() {
                this.displayErrorModalCreateStockMovement = true;

                let totalStockMovementFormErrors = Object.keys(this.stockMovementFormErrors).length;

                if (this.stockMovement?.type?.value !== 'in' && this.stockMovementFormErrors['buyPrice'] != null) totalStockMovementFormErrors--;

                return this.stockMovementFormErrors == null || totalStockMovementFormErrors === 0;
            },
            onSearchInputChange(input) {
                if (this.searchTimeout !== null) {
                    clearTimeout(this.searchTimeout);
                }

                this.searchTimeout = setTimeout(() => {
                    this.filter.search = input.value
                }, 250)
            },
            onSupplierAdd(supplier) {
                this.$nextTick(() => {
                    this.form.supplierCurrent = null;
                });

                // On ajoute le fournisseur s'il n'est pas déjà présent
                if (supplier.value != null && this.article.suppliers.filter(element => element.value === supplier.value.value).length === 0) {
                    this.article.suppliers.push(supplier.value);
                }
            },
            onArticleAdd(article) {
                this.$nextTick(() => {
                    this.form.articleCurrent = null;
                    this.form.articlesEstablishmentFilter = null;
                });

                // On ajoute l'article s'il n'est pas déjà présent
                if (article.value != null && this.article.articles.filter(element => element.value === article.value.value).length === 0) {
                    this.article.articles.push({
                        ...article.value,
                        quantity: 1,
                        unit: article.value.data.unit
                    });
                }
            },
            onValidateArticleForm() {
                if (this.createOrEditMode === 'create') {
                    this.createArticle();
                } else if (this.createOrEditMode === 'edit') {
                    this.editArticle();
                }
            },
            onModalExit() {
                this.closeModalCreateOrEditArticle();
            },
            closeModalCreateStockMovement() {
                this.resetStockMovement();
                this.displayModalCreateStockMovement = false;
                this.displayErrorModalCreateStockMovement = false;
            },
            formateStockMovement(stockMovement) {
                stockMovement = JSON.parse(JSON.stringify(stockMovement));
                stockMovement.affairId = stockMovement.affair?.value;
                stockMovement.articleId = stockMovement.article.value;
                stockMovement.movement = parseFloat(stockMovement.type.value === 'in' ? stockMovement.quantity : -stockMovement.quantity);
                stockMovement.buyPrice = parseFloat(stockMovement.buyPrice);
                stockMovement.kind = stockMovement.type.value === 'in' ? stockMovement.kindIn?.value : stockMovement.kindOut?.value;
                delete stockMovement.article;
                delete stockMovement.type;
                delete stockMovement.quantity;
                return stockMovement;
            },
            createStockMovement() {
                if (!this.checkStockMovementForm()) return;

                let formatedStockMovement = this.formateStockMovement(this.stockMovement);

                axios
                .post('stockMovement/create', formatedStockMovement, {
                    toastSuccessMessage: `Mouvement de stock créé`,
                    toastError: false,
                    showPreloader: true
                })
                .then(() => {
                    this.closeModalCreateStockMovement();
                    this.emitter.emit('ag-grid-reload');

                    if (this.displayModalCreateOrEditArticle) this.displayEditArticleModal(this.article);
                })
                .catch((error) => {
                    if (error?.response?.status === 409) {
                        this.$refs.dialog.show({
                            type: 'confirm',
                            title: 'Confirmation',
                            message: `Si vous confirmez cette action, le stock de cet article passera en négatif. Êtes-vous sûr ?`
                        }).then(() => {
                            formatedStockMovement.force = true;

                            axios
                            .post('stockMovement/create', formatedStockMovement, {
                                toastSuccessMessage: `Mouvement de stock créé`,
                                toastError: false,
                                showPreloader: true
                            })
                            .then(() => {
                                this.closeModalCreateStockMovement();
                                this.emitter.emit('ag-grid-reload');

                                if (this.displayModalCreateOrEditArticle) this.displayEditArticleModal(this.article);
                            })
                        })
                        .catch(() => {});
                    } else {
                        createToast(error.response.data, {
                            position: 'bottom-right',
                            type: 'danger',
                            hideProgressBar: true,
                            showIcon: true,
                            transition: 'slide'
                        });
                    }
                });
            },
            getMenuItems() {
                let menuItems = [
                    { name: "kindFamily", label: "Nature / Famille", step: 1 },
                    { name: "general", label: "Général", step: 2 },
                    { name: "supplierArticle", label: "Fournisseurs / Kits", step: 3 }
                ];

                if (this.createOrEditMode === 'edit') {
                    menuItems.push({ name: "stock", label: "Stock", step: 4 }, { name: "analytic", label: "Analytique", step: 5 }, { name: "purchaseHistory", label: "Historique d'achat", step: 6 });
                }

                return menuItems.map(menuItem => {
                    let menuItemFormatted = {
                        name: menuItem.name,
                        label: menuItem.label
                    };

                    if (this.displayError) {
                        let step = `step${menuItem.step}`
                        if (this.articleFormErrors[step] == null || Object.keys(this.articleFormErrors[step]).length === 0) {
                            menuItemFormatted.checked = true;
                        } else {
                            menuItemFormatted.unchecked = true;
                        }
                    }

                    return menuItemFormatted;
                });
            },
            createStockMovementFromArticle() {
                axios
                .get(`article/${this.article.id}`, {
                    toastError: true,
                    showPreloader: true
                })
                .then((result) => {
                    this.displayCreateStockMovementModal({value: result.data.id, label: result.data.label, buyPrice: result.data.buyPrice});
                })
                .catch(() => {});
            },
            deleteOrEnableStockMovement(stockMovement) {
                this.$refs.dialog.show({
                    type: 'confirm',
                    title: 'Confirmation',
                    message: `Êtes-vous sûr de vouloir ${stockMovement.deleted ? 'réintégrer' : 'annuler'} ce mouvement de stock ?`
                }).then(() => {
                    axios
                    .put('stockMovement/changeDeleted/' + stockMovement.id, {
                        deleted: !stockMovement.deleted
                    }, {
                        toastSuccessMessage: `Modification effectuée`,
                        toastError: false,
                        showPreloader: true
                    })
                    .then(() => {
                        this.displayEditArticleModal(this.article);
                        this.emitter.emit('ag-grid-reload')
                    })
                    .catch((error) => {
                        if (error?.response?.status === 409) {
                            this.$refs.dialog.show({
                                type: 'confirm',
                                title: 'Confirmation',
                                message: `Si vous confirmez cette action, le stock de cet article passera en négatif. Êtes-vous sûr ?`
                            }).then(() => {
                                axios
                                .put('stockMovement/changeDeleted/' + stockMovement.id, {
                                    deleted: !stockMovement.deleted,
                                    force: true
                                }, {
                                    toastSuccessMessage: `Modification effectuée`,
                                    toastError: true,
                                    showPreloader: true
                                })
                                .then(() => {
                                    this.displayEditArticleModal(this.article);
                                    this.emitter.emit('ag-grid-reload')
                                });
                            })
                            .catch(() => {});
                        } else {
                            createToast(error.response.data, {
                                position: 'bottom-right',
                                type: 'danger',
                                hideProgressBar: true,
                                showIcon: true,
                                transition: 'slide'
                            });
                        }
                    });
                })
                .catch(() => {});
            },
            openAffair(affairId) {
                window.open(`/affaires?search=${affairId}`, '_blank').focus();
            },
            displayGenerateInventoryModal() {
                this.resetInventory();
                this.displayModalGenerateInventory = true;
            },
            resetInventory() {
                this.inventory = {
                    endedDate: new Date(),
                    basicInventoryWanted: true
                };
            },
            closeModalGenerateInventory() {
                this.resetInventory();
                this.displayModalGenerateInventory = false;
                this.displayErrorModalGenerateInventory = false;
            },
            submitModalGenerateInventory() {
                if (!this.checkInventoryForm()) return;

                let currentDate = new Date();
                currentDate.setHours(23);
                currentDate.setMinutes(59);
                currentDate.setSeconds(59);

                if (isAfter(parse(this.inventory.endedDate, 'dd/MM/yyyy', new Date()), currentDate)) {
                    this.$refs.dialog.show({
                        type: 'alert',
                        title: 'Attention',
                        message: `La date de fin ne peut pas être postérieure à la date du jour`
                    });
                } else {
                    const url = this.inventory.basicInventoryWanted ? 'article/inventory' : 'article/bulk_inventory';
                    axios
                    .get(`${url}?endedDate=${this.inventory.endedDate}`, {
                        toastSuccessMessage: `Inventaire généré`,
                        toastError: true,
                        showPreloader: true
                    })
                    .then((response) => {
                        window.open(`${config.BACK_URL}/${config.GENERATED_DIRECTORY}/${response.data}`, '_blank');
                        this.closeModalGenerateInventory();
                    });
                }
            },
            checkInventoryForm() {
                this.displayErrorModalGenerateInventory = true;

                return this.inventoryFormErrors == null || Object.keys(this.inventoryFormErrors).length === 0;
            },
            displayArchivedArticles(state) {
                this.filter.archivedArticles = state;
            }
        }
    }
</script>
