<template>
    <div class="container-action-button" v-if="params.data">
        <BaseButton v-if="this.params.data.deleted === false" @click="onDeleteOrEnableStockMovement" :disabled="this.params.data.type !== 'movement'" buttonText="" title="Supprimer le mouvement">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'trash']" />
            </template>
        </BaseButton>
        <!--<BaseButton v-if="this.params.data.deleted === true" @click="onDeleteOrEnableStockMovement" :disabled="this.params.data.type !== 'movement'" buttonText="" title="Restaurer le mouvement">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'trash-restore']" />
            </template>
        </BaseButton>-->
    </div>
</template>

<script>
    import BaseButton from '../../components/Base/BaseButton.vue';
    import * as Vue from "vue";
    import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

    export default {
        name: 'ListStockMovementsActionButtonsRenderer',
        components: {
            BaseButton: Vue.shallowRef(BaseButton),
            FontAwesomeIcon: Vue.shallowRef(FontAwesomeIcon)
        },
        methods: {
            onDeleteOrEnableStockMovement() {
                let duplicatedParams = this.params.data;
                if (typeof this.params.data?.id === 'string') {
                    duplicatedParams.id = parseInt(this.params.data?.id.split('_')?.[0]);
                }
                this.params.context.componentParent.deleteOrEnableStockMovement(duplicatedParams);
            },
        }
    }
</script>