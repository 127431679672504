<template>
    <div class="container-action-button">
        <BaseButton v-if="this.params?.data?.deleted === false" @click="onDeleteOrEnableArticle" buttonText="" title="Archiver l'article">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'archive']" />
            </template>
        </BaseButton>
        <BaseButton v-if="this.params?.data?.deleted === true" @click="onDeleteOrEnableArticle" buttonText="" title="Désarchiver l'article">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'undo']" />
            </template>
        </BaseButton>
        <BaseButton @click="onEditArticle" buttonText="" title="Modifier l'article">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'pen']" />
            </template>
        </BaseButton>
        <BaseButton @click="onCopyArticle" buttonText="" title="Dupliquer l'article">
            <template v-slot:iconBefore>
                <font-awesome-icon :icon="['fas', 'copy']" />
            </template>
        </BaseButton>
    </div>
</template>

<script>
    import BaseButton from '../../components/Base/BaseButton.vue';
    import * as Vue from "vue";
    import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

    export default {
        name: 'ListArticlesActionButtonsRenderer',
        components: {
            BaseButton: Vue.shallowRef(BaseButton),
            FontAwesomeIcon: Vue.shallowRef(FontAwesomeIcon)
        },
        methods: {
            onDeleteOrEnableArticle() {
                this.params.context.componentParent.deleteOrEnableArticle(this.params.data);
            },
            onEditArticle() {
                this.params.context.componentParent.displayEditArticleModal(this.params.data);
            },
            onCopyArticle() {
                this.params.context.componentParent.displayCopyArticleModal(this.params.data);
            }
        }
    }        
</script>